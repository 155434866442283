<template>
  <v-row class="custom-grey-border" :class="{ 'ml-0': !vShowCalendar }">
    <v-col
      md="12"
      class="light-gray-background custom-border-bottom d-flex justify-space-between"
    >
      <div
        style="font-size: 19px"
        class="text-uppercase font-weight-700 color-custom-blue"
      >
        Team ({{ assignedTeam?.length }})
      </div>

      <div>
        <v-menu
          v-if="false"
          bottom
          left
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              right
              small
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-account-group</v-icon>Assign
            </v-btn>
          </template>
          <v-list class="light-gray-background pb-0" min-width="340">
            <v-list-item-title
              class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
            >
              Select Team
            </v-list-item-title>

            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 50vh; position: relative"
            >
              <v-list-item
                class="white-background"
                v-for="(team, index) in availableTeam"
                :key="index"
              >
                <v-checkbox
                  :input-value="assignedTeam"
                  :placeholder="team.engineer_display_name"
                  :value="team.engineer_id"
                  hide-details
                  color="cyan"
                  class="mr-4 mt-0"
                  v-on:change="updateScheduleState('dbAssignedTeam', $event)"
                  multiple
                >
                  <template v-slot:label>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-img
                          :lazy-src="$defaultProfileImage"
                          :src="team.engineer_profile_logo"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-size-16 font-weight-500"
                          v-html="team.engineer_display_name"
                        />
                        <v-list-item-subtitle
                          class="font-size-16 font-weight-500"
                          v-if="team.engineer_designation"
                          v-html="team.engineer_designation"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-checkbox>
              </v-list-item>
            </perfect-scrollbar>
          </v-list>
        </v-menu>
        <v-btn
          v-if="availableTeam.length"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          right
          small
          depressed
          @click="show_engineers = true"
        >
          <v-icon class="mr-2">mdi-account-group</v-icon>Assign
        </v-btn>
        <v-btn
          v-else
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          right
          small
          v-on:click="getEngineers()"
          depressed
          :disabled="jobLoading"
        >
          <v-icon class="mr-2">mdi-account-group</v-icon>Get Available
        </v-btn>
      </div>
    </v-col>
    <v-col md="12">
      <v-row>
        <v-col v-if="assignedTeamObject.length > 0" md="12" class="pa-0">
          <v-list-item
            v-for="(row, index) in assignedTeamObject"
            :key="index"
            class="border-bottom rounded px-2 mb-2 grey lighten-4"
          >
            <v-list-item-avatar class="my-1">
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="row.engineer_profile_logo"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-size-14 font-weight-500">
                {{ row.engineer_display_name }}
                <template v-if="row.mark_as_tl">
                  <v-chip label small color="blue" outlined class="ml-2">
                    Team Lead
                  </v-chip>
                </template>
              </v-list-item-title>
              <v-list-item-subtitle
                class="font-size-12 font-weight-500"
                v-if="row.engineer_designation"
              >
                <v-chip
                  label
                  color="red"
                  small
                  outlined
                  style="font-size: 10px"
                >
                  {{ row?.engineer_designation }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon class="my-1" link>
              <v-btn
                v-on:click="removeEngineer(row.engineer_id)"
                icon
                color="red lighten-1"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-col>
        <v-col v-else md="12">
          <p class="font-size-16">No users are currently assigned</p>
        </v-col>
      </v-row>
    </v-col>
    <Dialog :common-dialog="show_engineers" :dialog-width="900">
      <template v-slot:title>Team ({{ assignedTeam?.length }}) </template>
      <template v-slot:body>
        <v-container fluid class="py-0">
          <v-row>
            <v-col md="12" class="pt-0 custom-listing-table">
              <div class="d-flex">
                <v-text-field
                  style="max-width: 300px"
                  v-model.trim="search"
                  v-on:click:clear="(search = null), filterData()"
                  clearable
                  dense
                  filled
                  flat
                  placeholder="Search"
                  hide-details
                  solo
                  color="cyan"
                  class="mr-2"
                  @keydown.enter="filterData()"
                  @keydown.tab="filterData()"
                ></v-text-field>
                <v-autocomplete
                  hide-details
                  v-model.trim="type"
                  class="mr-2"
                  :items="typeList"
                  dense
                  flat
                  filled
                  style="max-width: 250px"
                  placeholder="Type"
                  item-color="cyan"
                  color="cyan"
                  solo
                  @change="getRelatedSkill()"
                  item-value="value"
                  item-text="text"
                >
                </v-autocomplete>
                <v-autocomplete
                  hide-details
                  v-model.trim="related_text"
                  class="mr-2"
                  :items="skillList"
                  dense
                  flat
                  filled
                  style="max-width: 250px"
                  placeholder="Type"
                  item-color="cyan"
                  color="cyan"
                  solo
                  @change="filterData()"
                  item-value="text"
                  item-text="text"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Category(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <template v-if="item.value == 'all'"
                          >{{ item.text }}
                        </template>
                        <template v-else>{{ item.text }} </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>
            <v-col md="12" class="pt-0 custom-listing-table">
              <v-simple-table class="inner-simple-table" fixed-header>
                <template v-slot:default>
                  <thead
                    style="
                      background-color: rgb(243, 246, 249);
                      color: rgb(36, 50, 109);
                    "
                  >
                    <tr class="custom-border-bottom">
                      <td
                        class="font-size-16 font-weight-700 p-0"
                        width="15"
                      ></td>
                      <!--  <td class="font-size-16 font-weight-700 p-0">#</td> -->
                      <td class="font-size-16 font-weight-700 p-0">Name</td>
                      <td class="font-size-16 font-weight-700 p-0">Role</td>
                      <td class="font-size-16 font-weight-700 p-0">Skill</td>
                      <td class="font-size-16 font-weight-700 p-0">
                        Certificates
                      </td>
                      <td class="font-size-16 font-weight-700 p-0">Licenses</td>
                      <td class="font-size-16 font-weight-700 p-0" width="75">
                        Mark As TL
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <!--   <pre>{{
                      availableTeam}}</pre> -->
                    <template v-if="lodash.isEmpty(availableTeam) === false">
                      <tr
                        v-for="(row, index) in availableTeam"
                        :key="index"
                        :class="{ 'orange_lighten-5': index % 2 == 0 }"
                      >
                        <td class="p-0">
                          <v-checkbox
                            :input-value="assignedTeam"
                            :placeholder="row.engineer_display_name"
                            :value="row.engineer_id"
                            hide-details
                            color="cyan"
                            class="mr-4 mt-0"
                            v-on:change="
                              updateScheduleState('dbAssignedTeam', $event)
                            "
                            multiple
                          ></v-checkbox>
                        </td>
                        <!-- <td class="p-0">
                          <span class="font-weight-700">{{
                            row?.engineer_barcode
                          }}</span>
                        </td> -->
                        <td class="p-0">
                          <div class="d-flex">
                            <div class="mr-1">
                              <v-avatar size="35">
                                <v-img
                                  class="customer-image white-background"
                                  width="35"
                                  height="35"
                                  :lazy-src="$defaultProfileImage"
                                  :src="
                                    row.engineer_profile_logo
                                      ? row.engineer_profile_logo
                                      : $defaultProfileImage
                                  "
                                >
                                </v-img>
                              </v-avatar>
                            </div>
                            <div>
                              <span class="font-weight-700 text-capitalize">{{
                                row?.engineer_display_name
                              }}</span>
                              <span v-if="row?.engineer_disabled == 1">
                                <v-chip
                                  label
                                  small
                                  color="red"
                                  class="text-white"
                                  >Unavailable</v-chip
                                ></span
                              >
                              <div>
                                <v-chip
                                  label
                                  color="red"
                                  small
                                  outlined
                                  style="font-size: 10px"
                                >
                                  {{ row?.engineer_designation }}
                                </v-chip>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="p-0">
                          <template v-if="row && row.engineer_role == 2">
                            <span class="font-weight-700">Technician</span>
                          </template>
                        </td>
                        <td class="p-0">
                          <template v-if="row?.all_skills?.length > 0">
                            <v-chip
                              v-if="row?.all_skills[0]?.certificate_name"
                              small
                              :color="row?.all_skills[0]?.color"
                              label
                              outlined
                              :text-color="
                                row?.all_skills[0]?.color ? '' : 'black'
                              "
                            >
                              {{ row?.all_skills[0]?.certificate_name }}
                            </v-chip>
                            <div class="mt-2">
                              <v-chip
                                v-if="row?.all_skills[1]?.certificate_name"
                                small
                                :color="row?.all_skills[1]?.color"
                                label
                                outlined
                                :text-color="
                                  row?.all_skills[1]?.color ? '' : 'black'
                                "
                              >
                                {{ row?.all_skills[1]?.certificate_name }}
                              </v-chip>
                            </div>
                            <template v-if="row?.all_skills?.length > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-1 mt-1"
                              >
                                <span class="font-size-16 font-weight-500">
                                  <template v-if="row?.all_skills?.length == 2">
                                    +{{ row?.all_skills?.length - 1 }} More
                                  </template>
                                  <template v-if="row?.all_skills?.length > 2">
                                    +{{ row?.all_skills?.length - 2 }} More
                                  </template>
                                </span>
                              </v-chip>
                            </template>
                          </template>
                          <em v-else class="text-muted"> no skills </em>
                        </td>
                        <td class="p-0">
                          <template v-if="row?.all_certificates?.length > 0">
                            <v-chip
                              v-if="row?.all_certificates[0]?.certificate_name"
                              small
                              :color="row?.all_certificates[0]?.color"
                              label
                              outlined
                              :text-color="
                                row?.all_certificates[0]?.color ? '' : 'black'
                              "
                            >
                              {{ row?.all_certificates[0]?.certificate_name }}
                            </v-chip>
                            <div class="mt-2">
                              <v-chip
                                v-if="
                                  row?.all_certificates[1]?.certificate_name
                                "
                                small
                                :color="row?.all_certificates[1]?.color"
                                label
                                outlined
                                :text-color="
                                  row?.all_certificates[1]?.color ? '' : 'black'
                                "
                              >
                                {{ row?.all_certificates[1]?.certificate_name }}
                              </v-chip>
                            </div>
                            <template v-if="row?.all_certificates?.length > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-1 mt-1"
                              >
                                <span class="font-size-16 font-weight-500">
                                  <template
                                    v-if="row?.all_certificates?.length == 2"
                                  >
                                    +{{ row?.all_certificates?.length - 1 }}
                                    More
                                  </template>
                                  <template
                                    v-if="row?.all_certificates?.length > 2"
                                  >
                                    +{{ row?.all_certificates?.length - 2 }}
                                    More
                                  </template>
                                </span>
                              </v-chip>
                            </template>
                          </template>
                          <em v-else class="text-muted"> no certificates </em>
                        </td>
                        <td class="p-0">
                          <template v-if="row?.all_licenses?.length > 0">
                            <v-chip
                              v-if="row?.all_licenses[0]?.certificate_name"
                              small
                              :color="row?.all_licenses[0]?.color"
                              label
                              outlined
                              :text-color="
                                row?.all_licenses[0]?.color ? '' : 'black'
                              "
                            >
                              {{ row?.all_licenses[0]?.certificate_name }}
                            </v-chip>
                            <div class="mt-2">
                              <v-chip
                                v-if="row?.all_licenses[1]?.certificate_name"
                                small
                                :color="row?.all_licenses[1]?.color"
                                label
                                outlined
                                :text-color="
                                  row?.all_licenses[1]?.color ? '' : 'black'
                                "
                              >
                                {{ row?.all_licenses[1]?.certificate_name }}
                              </v-chip>
                            </div>
                            <template v-if="row?.all_licenses?.length > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-1 mt-1"
                              >
                                <span class="font-size-16 font-weight-500">
                                  <template
                                    v-if="row?.all_licenses?.length == 2"
                                  >
                                    +{{ row?.all_licenses?.length - 1 }} More
                                  </template>
                                  <template
                                    v-if="row?.all_licenses?.length > 2"
                                  >
                                    +{{ row?.all_licenses?.length - 2 }} More
                                  </template>
                                </span>
                              </v-chip>
                            </template>
                          </template>
                          <em v-else class="text-muted"> no licenses </em>
                        </td>
                        <td class="p-0" width="75">
                          <v-checkbox
                            :disabled="checkDisabled(row.engineer_id)"
                            color="cyan"
                            hide-details
                            class="mt-0"
                            small
                            :value="row.mark_as_tl"
                            :input-value="row.mark_as_tl"
                            v-on:change="updateAssignedTeamObject(row, $event)"
                          ></v-checkbox>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td :colspan="6">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            Sorry! No Team(s) Found.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="closeEngineer()"
        >
          Close
        </v-btn>
        <v-btn
          class="custom-bold-button ml-4"
          depressed
          v-on:click="show_engineers = false"
          color="cyan white--text"
          >Save</v-btn
        >
      </template>
    </Dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { GET } from "@/core/services/store/request.module";
import {
  REMOVE_ENGINEER,
  GET_AVAILABLE_ENGINEER,
  UPDATE_SCHEDULE_STATE,
  UPDATE_SCHEDULE_ENGINEER,
} from "@/core/services/store/job.module";
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { GET_TICKET } from "@/core/services/store/job.module";
import { find } from "lodash";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-ctx-schedule-team",
  data() {
    return {
      show_engineers: false,
      visitId: 0,
      ticketId: 0,
      search: null,
      type: "all",
      related_text: null,
      skillList: [],
      typeList: [
        { value: "all", text: "All" },
        { value: "skill", text: "Skill" },
        { value: "certificate", text: "Certificate" },
        { value: "license", text: "Licences" },
      ],
    };
  },
  methods: {
    checkDisabled(id) {
      if (find(this.assignedTeam, (row) => row == id)) {
        return false;
      }
      return true;
    },
    closeEngineer() {
      if (this.visitId > 0) {
        let key = "dbAssignedTeam";
        let value = this.assignedTeam;
        let statekey = "dbAssignedTeamObject";
        let stateValue = this.assignedTeamObject;
        /* console.log(this.assignedTeam,'assignedTeam')
      console.log(this.assignedTeamObject,'assignedTeamObject') */
        this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
        this.$store.commit(UPDATE_SCHEDULE_STATE, { statekey, stateValue });
        this.$store.commit(UPDATE_SCHEDULE_ENGINEER);
        this.show_engineers = false;
      } else {
        let key = "dbAssignedTeam";
        let value = [];
        let statekey = "dbAssignedTeamObject";
        let stateValue = [];
        this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
        this.$store.commit(UPDATE_SCHEDULE_STATE, { statekey, stateValue });
        this.$store.commit(UPDATE_SCHEDULE_ENGINEER);
        this.show_engineers = false;
      }
    },
    updateScheduleState(key, value) {
      this.teamUpdate = true;
      this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
      this.$store.commit(UPDATE_SCHEDULE_ENGINEER);
    },
    updateAssignedTeamObject(value, event) {
      let flagValue = false;
      if (event) {
        flagValue = true;
      }
      if (this.assignedTeamObject && this.assignedTeamObject.length > 0) {
        for (let i = this.assignedTeamObject?.length - 1; i >= 0; i--) {
          if (this.assignedTeamObject[i].engineer_id == value.engineer_id) {
            (this.assignedTeamObject[i].mark_as_tl = flagValue),
              (this.availableTeam[i].mark_as_tl = flagValue);
          } else {
            this.availableTeam[i].mark_as_tl = false;
            this.assignedTeamObject[i].mark_as_tl = false;
          }
        }
      }
      if (this.availableTeam && this.availableTeam.length > 0) {
        for (let i = this.availableTeam?.length - 1; i >= 0; i--) {
          if (this.availableTeam[i].engineer_id == value.engineer_id) {
            this.availableTeam[i].mark_as_tl = flagValue;
          } else {
            this.availableTeam[i].mark_as_tl = false;
          }
        }
      }
      let statekey = "dbAssignedTeamObject";
      this.$store.commit(UPDATE_SCHEDULE_STATE, { statekey, value });
    },

    removeEngineer(id) {
      this.$store.commit(REMOVE_ENGINEER, id);
    },
    filterData() {
      if (this.visitId > 0) {
        this.$store.dispatch(GET_TICKET, {
          ticket: this.ticketId,
          visit: this.visitId,
          /*   search: this.search ? this.search : "",
          related_text: this.related_text ? this.related_text : "",
          type: this.type ? this.type : "", */
        });
      } else {
        this.getEngineers();
      }
    },
    getRelatedSkill() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: `related-skill/${this.type}`,
        })
        .then(({ data }) => {
          _this.skillList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getEngineers() {
      let schedule = new Object();

      if (this.scheduleType == "one-off") {
        schedule = this.oneOffSchedule;
      }

      if (this.scheduleType == "recurring") {
        schedule = this.recurringSchedule;
      }

      this.$store.dispatch(GET_AVAILABLE_ENGINEER, {
        search: this.search,
        related_text: this.related_text ? this.related_text : "",
        type: this.type ? this.type : "",
        start_date: moment(schedule.start_date).format("YYYY-MM-DD"),
        start_time: moment(
          `${schedule.start_date} ${schedule.start_time}`
        ).format("HH:mm"),
        end_date: moment(schedule.end_date).format("YYYY-MM-DD"),
        end_time: moment(`${schedule.end_date} ${schedule.end_time}`).format(
          "HH:mm"
        ),
        force: this.visitId && this.visitId > 0 ? 1 : 0,
      });
    },
  },
  computed: {
    ...mapGetters([
      "vShowCalendar",
      "oneOffSchedule",
      "recurringSchedule",
      "jobLoading",
      "availableTeam",
      "assignedTeam",
      "assignedTeamObject",
      "scheduleType",
    ]),
  },
  components: {
    Dialog,
  },
  created() {
    this.visitId = Number(this.$route?.query?.visit ?? 0);
    this.ticketId = Number(this.$route?.query?.ticket ?? 0);
  },
  mounted() {
    this.getRelatedSkill();
  },
};
</script>
