<template>
  <div class="position-relative">
    <v-text-field
      :id="id"
      :ref="inputRef"
      :label="label"
      :loading="loading || pageLoading"
      :type="type"
      :disabled="disabled || pageLoading"
      :clearable="clearable"
      :readonly="readonly"
      :prefix="prefix"
      :suffix="`${getSuffix}`"
      :hide-details="hideDetails"
      :hide-spin-buttons="hideSpinButtons"
      dense
      filled
      counter="50"
      solo
      flat
      :rules="[...rules, fieldNameError ? false : true]"
      :autofocus="autofocus"
      v-mask="vMask"
      class="pt-0"
      :class="{ ...customClass, 'mt-3': !hideTopMargin }"
      v-model.trim="textinput"
      :append-outer-icon="appendOuterIcon"
      :append-icon="appendIcon"
      :prepend-inner-icon="prependInnerIcon"
      :placeholder="placeholder"
      v-on:click:clear="$emit('click:clear', true)"
      v-on:click:append-outer="$emit('click:append-outer', true)"
      v-on:change="handleChange"
      v-on:keyup="handleKeyup"
      v-on:keypress="handleKeypress"
      v-on:click="$emit('click', true)"
      v-on:blur="handleBlur"
      v-on:focus="isFocused = true"
      v-bind="vBindAttrs"
      :reverse="reverse"
      v-on="vOn"
      v-on:paste="handlePaste"
    ></v-text-field>

    <template v-if="isFocused">
      <div
        class="suggested-content elevation-2"
        v-if="suggestedItems && suggestedItems.length"
      >
        <template v-for="(item, index) in suggestedItems">
          <v-list-item :key="index" class="py-2">
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
            <span class="red--text px-3 pt-1">Already exists</span>
          </v-list-item>
          <v-divider class="my-0" :key="`index--${index}`"></v-divider>
        </template>
      </div>
    </template>

    <span
      v-if="fieldNameError"
      v-html="fieldNameError"
      class="red--text text--darken-1 font-small"
    ></span>
  </div>
</template>

<script>
import { GetExistingData, ValidateInputField } from "@/core/lib/common.lib";

export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    uniqueModel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
    fieldName: {
      type: String,
      default: null,
    },
    urlType: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    inputRef: {
      type: String,
      default: "textInput",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    vBindAttrs: {
      type: Object,
      default: () => ({}),
    },
    vOn: {
      type: Object,
      default: () => ({}),
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideTopMargin: {
      type: Boolean,
      default: false,
    },
    hideSpinButtons: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    customClass: {
      type: String,
      default: "",
    },
    vMask: {
      type: String,
      default: "",
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    showDropdown: {
      type: Boolean,
      default: false,
    },
    validationField: {
      type: Object,
      default: () => ({}),
    },
    parentId: {
      type: [String, Number],
      default: null,
    },
    currentId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      textinput: this.value,
      fieldNameError: null,
      previousText: this.value,
      pageLoading: false,
      isFocused: false,
      timeout: null,
      suggestedItems: [],
    };
  },
  watch: {
    value(newVal) {
      this.textinput = newVal;
    },
    textinput(newVal) {
      this.$emit("input", newVal);
      if (this.counter) {
        this.textinput = newVal.substring(0, this.counter);
      }
    },
  },
  methods: {
    handleChange() {
      this.validateFieldName(this.textinput);
      this.$emit("change", true);
      if (this.uniqueModel) {
        if (
          this.suggestedItems &&
          this.suggestedItems.some((item) => item.text === this.textinput)
        ) {
          this.textinput = "";
        }
      }
    },
    handleKeyup(e) {
      this.getExistingData(this.textinput);
      this.$emit("keyup", e);
    },
    handleKeypress(e) {
      this.manageLimit(e);
      this.getExistingData(this.textinput);
      this.$emit("keypress", e);
    },
    handleBlur() {
      this.isFocused = false;
      this.$emit("blur", this.textinput);
    },
    handlePaste(e) {
      const maxLength = this.counter || 50;
      let copied = e.clipboardData.getData("Text");
      let finalVal = (this.textinput || "") + copied;

      if (finalVal.length > maxLength) {
        this.textinput = finalVal.substring(0, maxLength);
        e.preventDefault();
      }
      this.$emit("paste", e);
    },
    manageLimit(e) {
      const maxLength = this.counter || 50;
      if (this.textinput && this.textinput.length >= maxLength) {
        e.preventDefault();
      }
      this.$emit("keypress", e);
    },
    validateFieldNameOld() {
      this.fieldNameError = null;
      if (!this.textinput) return false;

      this.pageLoading = true;
      return new Promise((resolve, reject) => {
        ValidateInputField(
          `validate/${this.validationField.url_type}/any-field`,
          {
            filter_type: this.validationField?.filter_type || null,
            field: this.validationField?.field || null,
            value: this.textinput,
            parentId: this.parentId,
            id: this.currentId,
          }
        )
          .then((output) => {
            if (output.exists) {
              this.fieldNameError = `<b class='fw-600'>${this.textinput}</b> already exists`;
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(reject)
          .finally(() => {
            this.pageLoading = false;
          });
      });
    },
    validateFieldName() {
      this.fieldNameError = null;
      if (!this.textinput) return false;

      this.pageLoading = true;
      return new Promise((resolve, reject) => {
        ValidateInputField(
          `validate/${this.validationField.url_type}/any-field`,
          {
            filter_type: this.validationField?.filter_type || null,
            field: this.validationField?.field || null,
            value: this.textinput,
            parentId: this.parentId,
            id: this.currentId,
          }
        )
          .then((output) => {
            if (output.exists) {
              this.fieldNameError = `<b class='fw-600'>${this.textinput}</b> already exists`;

              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(reject)
          .finally(() => {
            this.pageLoading = false;
          });
      });
    },
    getExistingData(search_key) {
      if (search_key && this.showDropdown) {
        const params = {
          filter_type: this.validationField?.filter_type || null,
          field: this.validationField?.field || null,
          filter: this.validationField?.field || null,
          value: search_key,
          parentId: this.parentId,
          id: this.currentId,
        };

        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          const data = await GetExistingData(
            this.validationField.url_type,
            params
          );
          this.suggestedItems = data.data;
        }, 300);
      }
    },
  },
  computed: {
    counter() {
      return this.$vBindAttrs?.counter || 50;
    },
    getSuffix() {
      const totalLength = this.$attrs.counter;
      const typedCharLength = this.textinput?.length || 0;
      return totalLength ? `${typedCharLength}/${totalLength}` : "";
    },
  },
  mounted() {
    this.previousText = this.value;
    this.textinput = this.value;
  },
};
</script>


<style scoped>
.suggested-content {
  position: absolute;
  top: 30px;
  width: 100%;
  /* background: #fbf8f8; */
  background: #fff;
  z-index: 99;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
</style>
