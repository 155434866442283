<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
      <v-layout class="d-block">
        <v-row>
          <v-col class="iv-custom-field" cols="9" md="9" sm="9">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="max-height: calc(100vh - 172px); position: relative"
            >
              <v-container fluid>
                <v-row v-if="lodash.isEmpty(customer)" class="mb-2">
                  <v-col lg="3" class="my-auto py-0">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_type"
                      >Customer Type
                    </label>
                  </v-col>
                  <v-col lg="8" class="my-auto py-0">
                    <v-radio-group
                      v-model="detail.company_type"
                      row
                      hide-details
                      class="mt-0"
                      v-on:change="resetData"
                    >
                      <v-radio
                        style="margin-right: -2px"
                        value="company"
                        color="blue"
                      ></v-radio>
                      <v-chip
                        label
                        color="blue"
                        outlined
                        class="text-white p-3 mr-5"
                        small
                        >Company</v-chip
                      >
                      <v-radio
                        value="personal"
                        color="red"
                        style="margin-right: -2px"
                      ></v-radio>
                      <v-chip
                        label
                        color="red"
                        outlined
                        class="text-white p-3"
                        small
                        >Individual</v-chip
                      >
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="lodash.isEmpty(customer)" class="mb-2">
                  <v-col class="pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_type"
                      >Primary Contact</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="3" sm="12">
                    <!--  <label class="font-size-16 font-weight-500" for="salutation"
                      >Salutation</label
                    > -->
                    <v-select
                      id="salutation"
                      v-model.trim="detail.salutation"
                      :items="salutationList"
                      dense
                      filled
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      placeholder="Salutation"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col>
                  <v-col class="pt-0" md="3" sm="12">
                    <!-- <label
                      class="font-size-16 font-weight-500 required"
                      for="first_name"
                      >First Name</label
                    > -->

                    <v-text-field
                      id="first_name"
                      v-model.trim="detail.first_name"
                      :rules="[
                        validateRules.required(detail.first_name, 'first name'),
                        validateRules.minLength(
                          detail.first_name,
                          'first name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.first_name,
                          'first name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="First Name"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                      v-on:keyup="appendDisplayName()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0" md="3" sm="12">
                    <!-- <label class="font-size-16 font-weight-500" for="last_name"
                      >Last Name</label
                    > -->
                    <v-text-field
                      id="last_name"
                      v-model.trim="detail.last_name"
                      :rules="[
                        validateRules.minLength(
                          detail.last_name,
                          'last name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.last_name,
                          'last name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Last Name"
                      solo
                      flat
                      color="cyan"
                      v-on:keyup="appendDisplayName()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col md="12" sm="12">
                    <v-switch
                      v-model.trim="detail.primary"
                      class="mt-0"
                      inset
                      color="cyan"
                      label="Use company name as the display name"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row class="mb-2" v-if="detail.company_type == 'company'">
                  <v-col md="3" sm="12" class="pt-0 my-auto">
                    <label
                      class="font-size-16 font-weight-500 mt-0"
                      for="company_name"
                      :class="{ required: detail.primary }"
                      >Company Name</label
                    >
                  </v-col>
                  <v-col md="9" sm="12" class="pt-0">
                    <TextValidateInput
                      :disabled="pageLoading"
                      :hideTopMargin="true"
                      :loading="pageLoading"
                      :rules="company_rules()"
                      id="company-name"
                      placeholder="Company Name"
                      :validationField="{
                        url_type: 'customer',
                        filter_type: 'contact',
                        field: 'company_name',
                      }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.company_name"
                      v-on:keyup="appendDisplayName()"
                    ></TextValidateInput>
                  </v-col>

                  <v-col v-if="false" md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="entity-id"
                      >Entity</label
                    >
                    <v-select
                      id="entity-id"
                      v-model.trim="detail.entity_id"
                      :items="entityList"
                      dense
                      filled
                      :rules="[
                        validateRules.required(detail.entity_id, 'entity'),
                      ]"
                      item-text="name"
                      item-value="id"
                      item-color="cyan"
                      placeholder="Entity"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col>
                  <!--  <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="group_id"
                      >Group</label
                    >
                    <v-select
                      id="group_id"
                      v-model.trim="detail.group_id"
                      :items="groupList"
                      dense
                      filled
                      :rules="[
                        validateRules.required(detail.group_id, 'group'),
                      ]"
                      item-text="text"
                      item-value="id"
                      item-color="cyan"
                      placeholder="Group"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col>
                  <v-col v-if="false" md="4" sm="12">
                    <PhoneTextField
                      id="t-phone"
                      v-model="detail.phone"
                      :value="detail.phone"
                      required
                    >
                    </PhoneTextField>
                  </v-col>
                  <v-col md="6" sm="12">
                    <label class="font-size-16 font-weight-500" for="website"
                      >Website</label
                    >
                    <v-text-field
                      id="website"
                      v-model.trim="detail.website"
                      :rules="[
                        validateRules.minLength(detail.website, 'website', 3),
                        validateRules.maxLength(detail.website, 'website', 50),
                      ]"
                      dense
                      filled
                      placeholder="Website"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="false" md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="security_code"
                      >Security Code</label
                    >
                    <v-text-field
                      id="security_code"
                      v-model.trim="detail.security_code"
                      :rules="[
                        validateRules.required(
                          detail.security_code,
                          'security code'
                        ),
                        validateRules.minLength(
                          detail.security_code,
                          'security code',
                          3
                        ),
                        validateRules.maxLength(
                          detail.security_code,
                          'security code',
                          50
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Security Code"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_name"
                      >UEN</label
                    >
                    <v-text-field
                      id="uen"
                      v-model.trim="detail.uen"
                      dense
                      filled
                      placeholder="UEN"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col> -->

                  <template v-if="false">
                    <v-col md="4" sm="12">
                      <v-hover v-slot="{ hover }">
                        <div class="custom-google-autocomplete">
                          <v-text-field
                            append-icon="search"
                            :loading="autocompleteLoading"
                            v-on:keyup="getGoogleAddress"
                            v-on:blur="isFocused = false"
                            v-on:focus="isFocused = true"
                            v-model.trim="detail.postal_code"
                            :rules="[
                              validateRules.required(
                                detail.postal_code,
                                'postal code'
                              ),
                              validateRules.minLength(
                                detail.postal_code,
                                'postal code',
                                6
                              ),
                              validateRules.maxLength(
                                detail.postal_code,
                                'postal code',
                                6
                              ),
                            ]"
                            dense
                            filled
                            placeholder="Postal Code"
                            solo
                            flat
                            v-mask="'######'"
                            color="cyan"
                            class="required-field"
                          >
                          </v-text-field>
                          <v-list
                            v-if="
                              (hover || isFocused) && autocompleteList.length
                            "
                            two-line
                            elevation="4"
                            class="custom-google-autocomplete-list"
                          >
                            <v-list-item
                              v-for="(address, index) in autocompleteList"
                              :key="index"
                              link
                              v-on:click="selectGoogleAddress(index)"
                            >
                              <v-list-item-icon class="m-0 mr-3 my-auto">
                                <v-icon color="cyan"> mdi-map-marker </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content
                                class="py-1"
                                v-if="
                                  lodash.isEmpty(
                                    address.structured_formatting
                                  ) === false
                                "
                              >
                                <v-list-item-title class="font-size-14">{{
                                  address.structured_formatting.main_text
                                }}</v-list-item-title>
                                <v-list-item-subtitle class="font-size-12">{{
                                  address.structured_formatting.secondary_text
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-content class="py-1" v-else>
                                <v-list-item-title class="font-size-14">{{
                                  address.description
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-hover>
                    </v-col>
                    <v-col md="4" sm="12">
                      <v-text-field
                        v-model.trim="detail.unit_number"
                        :rules="[
                          validateRules.minLength(
                            detail.unit_number,
                            'unit no',
                            1
                          ),
                          validateRules.maxLength(
                            detail.unit_number,
                            'unit no',
                            100
                          ),
                        ]"
                        dense
                        filled
                        placeholder="Unit No."
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" sm="12">
                      <v-text-field
                        v-model.trim="detail.address_line_1"
                        :rules="[
                          validateRules.minLength(
                            detail.address_line_1,
                            'address line 1',
                            3
                          ),
                          validateRules.maxLength(
                            detail.address_line_1,
                            'address line 1',
                            50
                          ),
                        ]"
                        dense
                        filled
                        placeholder="Address Line 1"
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" sm="12">
                      <v-text-field
                        v-model.trim="detail.address_line_2"
                        :rules="[
                          validateRules.minLength(
                            detail.address_line_2,
                            'address line 2',
                            3
                          ),
                          validateRules.maxLength(
                            detail.address_line_2,
                            'address line 2',
                            50
                          ),
                        ]"
                        dense
                        filled
                        placeholder="Address Line 2"
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
                <v-row v-if="detail.company_type == 'company'" class="mb-2">
                  <v-col class="pt-0 my-auto" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required mt-0"
                      for="display_name"
                      >Display Name</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[
                        validateRules.required(
                          detail.display_name,
                          'Display Name'
                        ),
                      ]"
                      :hideTopMargin="true"
                      id="display-name"
                      placeholder="Display Name"
                      :validationField="{
                        url_type: 'customer',
                        filter_type: 'contact',
                        field: 'display_name',
                      }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.display_name"
                    >
                    </TextValidateInput>
                  </v-col>
                  <v-col class="pt-0 text-right my-auto" md="1" sm="12">
                    <label class="font-size-16 font-weight-500" for="uen"
                      >UEN</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <TextValidateInput
                      :hideTopMargin="true"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[validateRules.minLength(detail.uen, 'UEN', 3)]"
                      id="display-name"
                      placeholder="UEN"
                      :validationField="{
                        url_type: 'customer',
                        filter_type: 'contact',
                        field: 'uen',
                      }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.uen"
                    >
                    </TextValidateInput>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col class="pt-0" md="3" sm="12">
                    <label class="font-size-16 font-weight-500" for="sap_cust"
                      >Customer Ref #</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <TextField
                      id="sap_cust"
                      dense
                      filled
                      placeholder="Customer Ref #"
                      solo
                      flat
                      counter="50"
                      v-model="detail.sap_customer_no"
                      color="cyan"
                      :maxlength="50"
                    />
                  </v-col>
                  <v-col class="pt-0 text-right my-auto" md="1" sm="12">
                    <label class="font-size-16 font-weight-500" for="uen"
                      >Ref No.</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <TextField
                      id="cust_vend_ref_no"
                      dense
                      filled
                      placeholder="Ref No."
                      solo
                      flat
                      counter="50"
                      v-model="detail.cust_vend_ref_no"
                      color="cyan"
                      :maxlength="50"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="detail.company_type == 'personal'" class="mb-2">
                  <v-col class="pt-0 mt-4" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="display_name"
                      >Display Name</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="9" sm="12">
                    <!--  <label class="font-size-16 font-weight-500" for="salutation"
                        >Salutation</label
                      > -->
                    <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[
                        validateRules.required(
                          detail.display_name,
                          'display name'
                        ),
                        validateRules.minLength(
                          detail.display_name,
                          'display name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.display_name,
                          'display name',
                          100
                        ),
                      ]"
                      id="display-name"
                      placeholder="Display Name"
                      :validationField="{
                        url_type: 'customer',
                        filter_type: 'contact_person',
                        field: 'display_name',
                      }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.display_name"
                    >
                    </TextValidateInput>
                  </v-col>
                </v-row>

                <v-row v-if="detail.company_type == 'personal'" class="mb-2">
                  <v-col class="pt-0 mt-1" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Email Address</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="9" sm="12">
                    <!--  <label class="font-size-16 font-weight-500" for="salutation"
                        >Salutation</label
                      > -->
                    <v-text-field
                      v-model.trim="detail.person_email"
                      :rules="[
                        validateRules.validEmail(detail.person_email, 'email'),
                        validateRules.minLength(
                          detail.person_email,
                          'email',
                          2
                        ),
                        validateRules.maxLength(
                          detail.person_email,
                          'email',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Email"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                    <!--    <template v-if="isFocusedU">
                      <div
                        class="suggested-content elevation-2"
                        v-if="suggestedItems6 && suggestedItems6.length"
                      >
                        <template v-for="(item, index) in suggestedItems6">
                          <v-list-item :key="index" class="py-2">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.person_email"
                              ></v-list-item-title>
                            </v-list-item-content>
                            <span class="red--text px-3 pt-1"
                              >Already exists
                            </span>
                          </v-list-item>
                          <v-divider
                            class="my-0"
                            :key="`index--${index}`"
                          ></v-divider>
                        </template>
                      </div>
                    </template> -->
                  </v-col>
                </v-row>

                <v-row v-if="detail.company_type == 'personal'" class="mb-2">
                  <v-col class="pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Mobile Number</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="9" sm="12">
                    <!--  <label class="font-size-16 font-weight-500" for="salutation"
                        >Salutation</label
                      > -->
                    <PhoneTextField
                      v-model="detail.mobile_number"
                      label="Mobile Number"
                    >
                    </PhoneTextField>
                  </v-col>
                </v-row>
                <v-row v-if="detail.company_type == 'personal'" class="mb-2">
                  <v-col class="pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Landline Number</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="9" sm="12">
                    <!--  <label class="font-size-16 font-weight-500" for="salutation"
                        >Salutation</label
                      > -->
                    <PhoneTextField
                      v-model="detail.landline_number"
                      label="Landline Number"
                    >
                    </PhoneTextField>
                  </v-col>
                </v-row>
                <v-row v-if="detail.company_type == 'company'" class="mb-2">
                  <v-col class="pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Company email address</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <!--  <label class="font-size-16 font-weight-500" for="salutation"
                            >Salutation</label
                          > -->
                    <v-text-field
                      v-model.trim="detail.company_email"
                      :rules="[
                        // validateRules.required(detail.company_email, 'email'),
                        validateRules.validEmail(detail.company_email, 'email'),
                        validateRules.minLength(
                          detail.company_email,
                          'email',
                          2
                        ),
                        validateRules.maxLength(
                          detail.company_email,
                          'email',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Email"
                      solo
                      flat
                      color="cyan"
                      v-on:blur="isFocusedU = false"
                      v-on:focus="isFocusedU = true"
                    ></v-text-field>
                    <!--  v-on:keyup="
                        getCustomerListEmail(
                          detail.company_email,
                          'company_email'
                        )
                      "-->

                    <template v-if="isFocusedU">
                      <div
                        class="suggested-content elevation-2"
                        v-if="suggestedItems5 && suggestedItems5.length"
                      >
                        <template v-for="(item, index) in suggestedItems5">
                          <v-list-item :key="index" class="py-2">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.company_email"
                              ></v-list-item-title>
                            </v-list-item-content>
                            <span class="red--text px-3 pt-1"
                              >Already exists
                            </span>
                          </v-list-item>
                          <v-divider
                            class="my-0"
                            :key="`index--${index}`"
                          ></v-divider>
                        </template>
                      </div>
                    </template>
                  </v-col>
                  <v-col class="pt-0 text-right my-auto" md="1" sm="12">
                    <label class="font-size-16 font-weight-500" for="website"
                      >Website</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <TextField
                      id="website"
                      dense
                      filled
                      placeholder="Website."
                      solo
                      flat
                      counter="50"
                      v-model="detail.website"
                      color="cyan"
                      :maxlength="50"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="detail.company_type == 'company'" class="mb-2">
                  <v-col class="pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Company phone number</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <PhoneTextField
                      v-model="detail.company_number"
                      v-on:blur="isFocusedN = false"
                      v-on:focus="isFocusedN = true"
                    >
                    </PhoneTextField>
                    <!--v-on:change="
                        getCustomerListMobile(
                          detail.company_number,
                          'company_number'
                        )
                      "-->
                  </v-col>
                  <template v-if="isFocusedN">
                    <div
                      class="suggested-content elevation-2"
                      v-if="suggestedItems4 && suggestedItems4.length"
                    >
                      <template v-for="(item, index) in suggestedItems4">
                        <v-list-item :key="index" class="py-2">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.company_number"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <span class="red--text px-3 pt-1"
                            >Already exists
                          </span>
                        </v-list-item>
                        <v-divider
                          class="my-0"
                          :key="`index--${index}`"
                        ></v-divider>
                      </template>
                    </div>
                  </template>
                  <v-col class="pt-0 text-right my-auto" md="1" sm="12">
                    <label class="font-size-16 font-weight-500" for="website"
                      >Company fax</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <PhoneTextField
                      v-model="detail.company_fax"
                      label="Company fax"
                    >
                    </PhoneTextField>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col class="pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Category</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <v-autocomplete
                      hide-details
                      v-model.trim="detail.category"
                      :items="options.all_cms_categories"
                      dense
                      flat
                      filled
                      placeholder="Category"
                      item-color="cyan"
                      color="cyan"
                      solo
                      return-object
                      item-value="value"
                      item-text="text"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Category(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-list-item-action class="ma-0">
                          <v-chip
                            style="height: 16px; width: 16px; padding: 0"
                            :color="item.color"
                          >
                          </v-chip>
                        </v-list-item-action>
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                          >
                            <template v-if="item.value == 'all'"
                              >{{ item.text }}
                            </template>
                            <template v-else>{{ item.text }} </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-action class="mr-0">
                          <v-chip
                            style="height: 16px; width: 16px; padding: 0"
                            :color="item.color"
                          >
                          </v-chip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="pt-0 text-right my-auto" md="1" sm="12">
                    <label class="font-size-16 font-weight-500" for="Tags"
                      >Tags</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <TagAutoComplete type="customer" v-model="detail.tags" />
                  </v-col>
                </v-row>
                <v-row v-if="detail.company_type == 'company'">
                  <v-col class="pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Customer Remark</label
                    >
                  </v-col>

                  <v-col class="pt-0" md="9" sm="12">
                    <v-textarea
                      v-model="detail.customer_remark"
                      auto-grow
                      dense
                      filled
                      color="cyan"
                      placeholder="Customer Remark"
                      solo
                      flat
                      row-height="15"
                      v-on:keypress="(e) => manageLimit(e)"
                      v-on:paste="(e) => onPaste(e)"
                    ></v-textarea>
                  </v-col>
                  <v-col md="12" class="text-right"
                    >{{
                      detail.customer_remark
                        ? detail.customer_remark.length
                        : 0
                    }}/200</v-col
                  >
                </v-row>
                <!--   <template v-for="(cdata, index) in contactDetails">
                    <v-row class="contact-detail-row" :key="'contact-' + index">
                      <v-col md="3">
                        <template v-if="cdata.title == 'main'">
                          <label
                              class="font-size-16 font-weight-500 required"
                              >Company phone number</label
                            >
                        </template>
                        <template v-if="cdata.title == 'fax'">
                          <label
                              class="font-size-16 font-weight-500"
                              >Company fax</label
                            >
                        </template>
                    
                      </v-col>
                      <v-col md="7">
                      
                        <PhoneTextField
                          :id="`contact-${index}`"
                          v-model="cdata.value"
                          :value="cdata.value"
                          :required="cdata.title == 'main'"
                        >
                        </PhoneTextField>
                      </v-col>
                      <v-col md="2" v-if="false">
                        <template v-if="index > 0">
                          <v-btn
                            class="mx-2 mt-6"
                            color="red lighten-1"
                            dark
                            fab
                            small
                            v-on:click="removeContactDetail(index)"
                          >
                            <v-icon dark> mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn
                            class="mx-2 mt-6"
                            color="cyan"
                            dark
                            fab
                            small
                            v-on:click="pushContactDetail"
                          >
                            <v-icon dark> mdi-plus</v-icon>
                          </v-btn>
                        </template>
                      </v-col>
                    </v-row>
                  </template> -->
                <template>
                  <!--  <template v-for="(cdata, index) in emailDetails">
                    <v-row class="contact-detail-row" :key="'email-' + index">
                      <v-col md="3">
                        <label
                          class="font-size-16 font-weight-500 required"
                          :for="`email-type-${index}`"
                          >Type</label
                        >
                        <v-select
                          :id="`email-type-${index}`"
                          v-model.trim="cdata.title"
                          :items="emailTypeList"
                          :rules="[validateRules.required(cdata.title, 'type')]"
                          dense
                          filled
                          item-text="text"
                          item-value="value"
                          item-color="cyan"
                          placeholder="Type"
                          solo
                          flat
                          color="cyan"
                        ></v-select>
                      </v-col>
                      <v-col md="7">
                        <label
                          class="font-size-16 font-weight-500 required"
                          :for="`email-${index}`"
                          >Email</label
                        >
                        <v-text-field
                          :id="`email-${index}`"
                          v-model.trim="cdata.value"
                          :rules="[
                            validateRules.required(cdata.value, 'email'),
                            validateRules.validEmail(cdata.value, 'email'),
                            validateRules.minLength(cdata.value, 'email', 2),
                            validateRules.maxLength(cdata.value, 'email', 100),
                          ]"
                          dense
                          filled
                          placeholder="Email"
                          solo
                          flat
                          color="cyan"
                          class="required-field"
                        ></v-text-field>
                      </v-col>
                      <v-col md="2" v-if="false">
                        <template v-if="index > 0">
                          <v-btn
                            class="mx-2 mt-30"
                            color="red lighten-1"
                            dark
                            fab
                            small
                            v-on:click="removeEmailDetail(index, cdata)"
                          >
                            <v-icon dark> mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
<template v-else>
                          <v-btn
                            class="mx-2 mt-30"
                            color="cyan"
                            dark
                            fab
                            small
                            v-on:click="pushEmailDetail"
                          >
                            <v-icon dark> mdi-plus</v-icon>
                          </v-btn>
                        </template>
</v-col>
</v-row>
</template>
<template v-for="(cdata, index) in contactDetails">
                    <v-row class="contact-detail-row" :key="'contact-' + index">
                      <v-col md="3">
                        <label
                          :class="`font-size-16 font-weight-500 ${
                            cdata.title == 'main' ? 'required' : ''
                          }`"
                          :for="`contact-type-${index}`"
                          >Type</label
                        >
                        <v-select
                          :id="`contact-type-${index}`"
                          v-model.trim="cdata.title"
                          :items="contactTypeList"
                          :rules="[validateRules.required(cdata.title, 'type')]"
                          dense
                          filled
                          item-text="text"
                          item-value="value"
                          item-color="cyan"
                          placeholder="Type"
                          solo
                          flat
                          color="cyan"
                          readonly
                        ></v-select>
                      </v-col>
                      <v-col md="7">
                        <label
                          :class="`font-size-16 font-weight-500 ${
                            cdata.title == 'main' ? 'required' : ''
                          }`"
                          :for="`contact-${index}`"
                          >Phone Number</label
                        >
                        <PhoneTextField
                          :id="`contact-${index}`"
                          v-model="cdata.value"
                          :value="cdata.value"
                          :required="cdata.title == 'main'"
                        >
                        </PhoneTextField>
                      </v-col>
                      <v-col md="2" v-if="false">
                        <template v-if="index > 0">
                          <v-btn
                            class="mx-2 mt-6"
                            color="red lighten-1"
                            dark
                            fab
                            small
                            v-on:click="removeContactDetail(index)"
                          >
                            <v-icon dark> mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
<template v-else>
                          <v-btn
                            class="mx-2 mt-6"
                            color="cyan"
                            dark
                            fab
                            small
                            v-on:click="pushContactDetail"
                          >
                            <v-icon dark> mdi-plus</v-icon>
                          </v-btn>
                        </template>
</v-col>
</v-row>
</template> -->
                </template>
                <v-row :class="{ 'd-none': detail.id }">
                  <CustomForm module="customer" :module-id="detail.id" />
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </v-col>
          <v-col
            class="iv-custom-field"
            cols="3"
            md="3"
            sm="3"
            v-on:mouseleave="imageTemplate = false"
            v-on:mouseover="imageTemplate = true"
          >
            <template>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="getProfileImage ? getProfileImage : $defaultProfileImage"
                max-height="200"
                max-width="200"
                height="200px"
                width="200px"
                class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
              >
              </v-img>
              <div class="margin-auto text-center">
                <v-btn
                  class="my-4 custom-bold-button"
                  outlined
                  small
                  v-on:click="selectImage"
                  color="cyan"
                  :loading="imageLoading"
                  :disabled="imageLoading"
                >
                  <template v-if="getProfileImage">Change Image</template>
                  <template v-else>Upload Image</template>
                </v-btn>
                <div class="d-none">
                  <v-file-input
                    ref="uploadImage"
                    v-on:change="updateProfileImage"
                    :rules="imageRules"
                    accept="image/png, image/jpeg, image/jpg"
                  ></v-file-input>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
        <!--    <v-row>
            <v-col md="12" sm="12">
              <h3>Contact Details</h3>
              <ContactPersons
                
                  ></ContactPersons>
            </v-col>
        </v-row> -->
      </v-layout>
    </v-col>
    <template v-if="false && !isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import CustomForm from "@/view/pages/custom-field-v2/CustomForm.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import { GET, QUERY, DELETE } from "@/core/services/store/request.module";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { getConfig } from "@/core/services/local.service";
import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";
import { SET_ACTIVE_FIELD_DATA } from "@/core/services/store/custom.fields.module.js";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
/* import ContactPersons from "@/view/pages/customer/create-or-update/Contact-Persons.vue"; */
export default {
  name: "contact-details",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin, FileManagerMixin],
  props: {
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    lead: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupList: [],
      pageLoading: false,
      entityList: [],
      proposal: 0,
      timeoutLimit: 500,
      timeout: null,
      autocompleteList: new Array(),
      suggestedItems: [],
      suggestedItems2: [],
      suggestedItems3: [],
      suggestedItems4: [],
      suggestedItems5: [],
      suggestedItems6: [],
      autocompleteLoading: false,
      isFocused: false,
      isFocusedU: false,
      isFocusedN: false,
      isFocusedD: false,
      custom_field_module_id: null,
      detail: {
        id: null,
        sap_customer_no: null,
        cust_vend_ref_no: null,
        primary: true,
        company_email: null,
        can_login: true,
        company_number: null,
        company_fax: null,
        person_email: null,
        landline_number: null,
        mobile_number: null,
        salutation: "mr",
        customer_remark: null,
        first_name: null,
        last_name: null,
        company_type: "company",
        company_name: null,
        uen: null,
        display_name: null,
        profile_logo: null,
        entity_id: 1,
        group_id: 1,
        phone: null,
        website: null,
        security_code: null,
        unit_number: null,
        address_line_1: null,
        address_line_2: null,
        category: null,
        category_color: null,
        tags: [],
        postal_code: null,
        country: null,
        state: null,
        city: null,
        details: [],
      },
      imageLoading: false,
      emailDetails: [
        {
          title: "main",
          value: null,
          type: 1,
          id: null,
        },
      ],
      contactDetails: [
        {
          title: "main",
          value: null,
          type: 2,
          id: null,
        },
        {
          title: "fax",
          value: null,
          type: 2,
          id: null,
        },
      ],
      imageTemplate: false,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    customer() {
      console.log(this.customer);
    },
    "detail.primary"() {
      if (this.detail.primary) {
        this.detail.display_name = this.detail.company_name;
      }
    },

    /* "detail.company_name"() {
      if (this.detail.primary) {
        //if(!this.detail.id){
        this.detail.display_name =
          this.detail.company_type == "company"
            ? this.detail.display_name
              ? this.detail.display_name
              : this.detail.company_name
            : this.detail.display_name;
        //}
      }
    }, */

    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("saveContactDetail", param);
      },
    },
    lead: {
      deep: true,
      immediate: true,
      handler() {
        this.getLead();
      },
    },
    emailDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
    contactDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
  },
  methods: {
    resetData() {
      (this.detail.salutation = "mr"),
        (this.detail.first_name = null),
        (this.detail.last_name = null),
        (this.detail.company_name = null),
        (this.detail.uen = null),
        (this.detail.display_name = null);
      this.detail.company_email = null;
      this.detail.website = null;
      this.detail.company_number = null;
      this.detail.company_fax = null;
      this.detail.customer_remark = null;
    },

    company_rules() {
      const rules = [];

      if (this.detail.primary) {
        rules.push(
          this.validateRules.required(this.detail.company_name, "display name")
        );
      }

      rules.push(
        this.validateRules.minLength(
          this.detail.company_name,
          "display name",
          2
        )
      );

      rules.push(
        this.validateRules.maxLength(
          this.detail.company_name,
          "display name",
          100
        )
      );

      return rules;
    },
    appendDisplayName() {
      const result = [];
      const { first_name, last_name } = this.detail;
      if (first_name) {
        result.push(first_name);
      }
      if (last_name) {
        result.push(last_name);
      }
      /* if (!this.detail.primary) {
        console.log(this.detail.primary) */
      if (this.detail.company_type == "personal") {
        this.detail.display_name = result.join(" ");
      }

      /*    } */
      if (this.detail.company_type == "company") {
        this.detail.display_name = this.detail.company_name;
      }
    },
    clearAddress() {
      const _this = this;
      _this.detail.postal_code = null;
      _this.detail.address_line_1 = null;
      _this.detail.address_line_2 = null;
      _this.detail.unit_number = null;
    },
    /*  manageLimit(e) {
      if (
        this.detail.customer_remark &&
        this.detail.customer_remark.length > 199
      ) {
        e.preventDefault();
      }
    }, */

    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.detail.customer_remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.detail.customer_remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.detail.customer_remark &&
        this.detail.customer_remark.length > 199
      ) {
        e.preventDefault();
      }
    },

    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    /* getExistingData(search_key,name) {
      this.suggestedItems = [];
      if (search_key) {
        let requestParams = {
          type: 'customers',
          name: name,
          value: search_key,
        };
        this.getCustomerList(requestParams);
      }
    }, */
    getCustomerList(search_key) {
      this.suggestedItems = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-company",
          data: {
            type: "customers",
            name: "company_name",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerListDisplay(search_key) {
      this.suggestedItems2 = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-company",
          data: {
            type: "customers",
            name: "display_name",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems2 = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerListEmail(search_key) {
      this.suggestedItems5 = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-customer",
          data: {
            type: "company",
            name: "company_email",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems5 = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerEmail(search_key) {
      this.suggestedItems6 = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-customer",
          data: {
            type: "individual",
            name: "primary_email",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems6 = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    getCustomerListMobile(search_key) {
      this.suggestedItems4 = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-customer",
          data: {
            type: "company",
            name: "company_number",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems4 = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerListUen(search_key) {
      this.suggestedItems3 = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-company",
          data: {
            type: "customers",
            name: "uen",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems3 = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.detail.postal_code = result.zip_code;
              _this.detail.address_line_1 = result.street_1;
              _this.detail.address_line_2 = result.street_2;
              _this.detail.unit_number = result.unit_no;
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.detail.postal_code && _this.detail.postal_code.length == 6) {
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.detail.postal_code },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        } else {
          _this.autocompleteList = new Array();
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateDetails() {
      const _this = this;
      _this.detail.details = [];
      for (let i = 0; i < _this.emailDetails.length; i++) {
        if (_this.emailDetails[i]) {
          delete _this.emailDetails[i].default;
          _this.detail.details.push(_this.emailDetails[i]);
        }
      }
      for (let z = 0; z < _this.contactDetails.length; z++) {
        if (_this.contactDetails[z]) {
          delete _this.contactDetails[z].default;
          _this.detail.details.push(_this.contactDetails[z]);
        }
      }
    },
    pushEmailDetail() {
      this.emailDetails.push({
        title: "main",
        value: null,
        type: 1,
        id: null,
      });
    },
    removeEmailDetail(index, row) {
      if (row && row.id) {
        this.$store
          .dispatch(DELETE, {
            url: `customer/${row.customer}/detail`,
            data: { details: [row.id] },
          })
          .then(() => {
            this.emailDetails.splice(index, 1);
          })
          .catch((error) => {
            this.logError(error);
          });
      } else {
        this.emailDetails.splice(index, 1);
      }
    },
    pushContactDetail() {
      this.contactDetails.push({
        title: "main",
        value: null,
        type: 2,
        id: null,
      });
    },
    removeContactDetail(index) {
      this.contactDetails.splice(index, 1);
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.detail.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },

    getGroupList() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "customer/group-list",
        })
        .then(({ data }) => {
          _this.groupList = data.group_list;
          if (_this.lodash.isEmpty(_this.customer) === true) {
            _this.detail.security_code = data.security_code;
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    editDetails() {
      const _this = this;

      _this.defaultPerson = _this.getDefaultPerson(_this.customer);
      _this.emailDetails = _this.getEmailDetails(_this.customer);
      _this.contactDetails = _this.getPhoneDetails(_this.customer);

      const faxContactDetails = _this.lodash.filter(_this.contactDetails, {
        title: "fax",
      });

      const mainContactDetails = _this.lodash.filter(_this.contactDetails, {
        title: "main",
      });

      if (_this.lodash.isEmpty(mainContactDetails)) {
        _this.contactDetails.push({
          title: "main",
          value: null,
          type: 2,
          id: null,
        });
      }

      if (_this.lodash.isEmpty(faxContactDetails)) {
        _this.contactDetails.push({
          title: "fax",
          value: null,
          type: 2,
          id: null,
        });
      }
      _this.detail.id = _this.customer.id;
      _this.detail.salutation = _this.defaultPerson.title;
      _this.detail.first_name = _this.defaultPerson.first_name;
      _this.detail.last_name = _this.defaultPerson.last_name;

      _this.detail.primary = _this.customer.primary;

      _this.detail.company_name = _this.customer.company_name;
      _this.detail.uen = _this.customer.uen;
      _this.detail.display_name = _this.customer.display_name;
      _this.detail.customer_remark = _this.customer.customer_remark;
      _this.detail.entity_id = _this.customer.entity_id;
      _this.detail.group_id = _this.customer.group_id;
      _this.detail.phone = _this.customer.phone;
      _this.detail.website = _this.customer.website;
      _this.detail.unit_number = _this.customer.unit_number;
      _this.detail.address_line_1 = _this.customer.address_line_1;
      _this.detail.address_line_2 = _this.customer.address_line_2;
      _this.detail.category = _this.customer.category;
      _this.detail.category_color = _this.customer.category_color;
      _this.detail.tags = _this.customer.tags;
      _this.detail.company_number = _this.customer.company_number;
      _this.detail.company_fax = _this.customer.company_fax;
      _this.detail.company_email = _this.customer.company_email;
      _this.detail.company_email = _this.customer.company_email;
      _this.detail.company_email = _this.customer.company_email;
      _this.detail.company_email = _this.customer.company_email;
      _this.detail.company_type = _this.customer.company_type;
      _this.detail.postal_code = _this.customer.postal_code;
      _this.detail.country = _this.customer.country;
      _this.detail.security_code = _this.customer.security_code;
      _this.detail.state = _this.customer.state;
      _this.detail.city = _this.customer.city;
      _this.detail.profile_logo = _this.customer.profile_logo;
      //sap detail
      _this.detail.sap_customer_no = _this.customer.sap_customer_no
        ? _this.customer.sap_customer_no
        : null;
      _this.detail.cust_vend_ref_no = _this.customer.cust_vend_ref_no
        ? _this.customer.cust_vend_ref_no
        : null;

      _this.$store.commit(SET_ACTIVE_FIELD_DATA, _this.customer.custom_fields);
    },
    getLead() {
      const _this = this;
      let fullname = _this.lead.name ? _this.lead.name.split(" ") : [];
      _this.detail.first_name = fullname[0];
      _this.detail.last_name = fullname[1];
      _this.detail.company_name = _this.lead.company;
      _this.detail.uen = _this.lead.uen;
      _this.detail.display_name = _this.lead.company;
      _this.detail.phone = _this.lead.phone_number;
      _this.detail.website = _this.lead.website;
      _this.detail.postal_code = _this.lead.zip;
      _this.detail.country = _this.lead.country;
      _this.detail.state = _this.lead.state;
      _this.detail.city = _this.lead.city;
      _this.detail.unit_number = _this.lead.unit_no;
      _this.detail.address_line_1 = _this.lead.address_1;
      _this.detail.address_line_2 = _this.lead.address_2;
      _this.detail.category = _this.lead.category;
      _this.detail.category_color = _this.lead.category_color;
      _this.detail.tags = _this.lead.tags;

      _this.emailDetails[0] = new Object({
        title: "main",
        value: _this.lead.email,
        type: 1,
        id: null,
      });

      _this.contactDetails[0] = new Object({
        title: "main",
        value: _this.lead.phone_number,
        type: 2,
        id: null,
      });

      _this.updateDetails();
    },
    getProposal() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal,
        })
        .then(({ data }) => {
          let fullname = data.name ? data.name.split(" ") : [];
          _this.detail.first_name = fullname[0];
          _this.detail.last_name = fullname[1];
          _this.detail.company_name = data.company;
          _this.detail.display_name = data.company;
          _this.detail.uen = data.uen;
          _this.detail.phone = data.phone_number;
          _this.detail.website = data.website;
          _this.detail.postal_code = data.zip;
          _this.detail.country = data.country;
          _this.detail.state = data.state;
          _this.detail.city = data.city;
          _this.detail.address_line_1 = data.address;

          _this.emailDetails[0] = new Object({
            title: "main",
            value: data.email,
            type: 1,
            id: null,
          });

          _this.contactDetails[0] = new Object({
            title: "main",
            value: data.phone_number,
            type: 2,
            id: null,
          });

          _this.updateDetails();
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.entityList = getConfig("entity");

    const firstEntity = _this.lodash.find(_this.entityList, { id: 1 });
    if (firstEntity) {
      _this.detail.entity_id = firstEntity.id;
    }

    _this.getGroupList();
    if (_this.lodash.isEmpty(_this.customer) === false) {
      _this.editDetails();
    }

    _this.proposal = _this.lodash.toSafeInteger(_this.$route.query.proposal);
    if (_this.proposal > 0) {
      _this.getProposal();
    }
  },
  components: {
    PhoneTextField,
    TextValidateInput,
    CustomForm,
    TagAutoComplete,
    /* ContactPersons */
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.detail.profile_logo) === false
        ? this.detail.profile_logo.file.url
        : null;
    },
  },
};
</script>
